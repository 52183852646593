import { Controller } from "@hotwired/stimulus"
import { Jodit } from "jodit"
import Rails from "@rails/ujs"

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["editor", "input", "loaded"]
  static values = { loaded: Boolean }

  editor = null

  connect() {
    if (this.loadedValue) {
      // musimy stworzyć edytor od nowa, bo inaczej nie będziemy mieli ustawionej wartości w this.editor
      const oldEditor = document.getElementsByClassName("jodit-container")[0]
      if (oldEditor) {
        oldEditor.remove()
      }
    }

    console.log('connect jodit')
    if (this.hasEditorTarget) {
      this.editor = this.createEditor()
    }

    this.updateFooter() // w edycji bez tego nie pokazywał się footer
    this.loadedValue = true
  }

  createEditor() {
    return Jodit.make(`#${this.editorTarget.id}`, {
      style: { font: "14px Arial" },
      tabIndex: 0,
      autofocus: this.editorTarget.autofocus,
      toolbarAdaptive: false,
      toolbarSticky: false,
      minHeight: "300px",
      colorPickerDefaultTab: 'text',
      buttons: [
        'source', '|',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent', '|',
        'font',
        'fontsize',
        'brush',
        'paragraph', '|',
        'image',
        'table',
        'link', '|',
        'align', 'undo', 'redo', '|',
        'hr',
        'eraser',
        'preview'
      ],
      controls: {
        paragraph: {
          list: Jodit.atom({
            p: 'Pharagraph',
            h2: 'Heading 1',
            h3: 'Heading 2',
            h4: 'Heading 3',
            h5: 'Heading 4',
            blockquote: 'Quote',
            pre: 'Source code'
          })
        }
      },
      enter: 'br',
      tabInsideLiInsertNewList: true,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      showPlaceholder: false,
      askBeforePasteHTML: false,
      language: this.editorTarget.dataset.locale || 'en',
      uploader: {
        url: this.editorTarget.getAttribute("data-direct-upload-url"),
        // insertImageAsBase64URI: true,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'],
        format: 'json',
        headers: {
          'X-CSRF-Token': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')
        },
        isSuccess: function (resp) {
          return !resp.error
        },
        getMessage: function (resp) {
          return resp.msg
        },
        process: function (resp) {
          return {
            attachments: resp.attachments || [],
            errors: resp.errors || [],
          }
        },
        defaultHandlerSuccess: function (data, resp) {
          var i
          var field = 'attachments'
          if (data[field] && data[field].length) {
            for (i = 0; i < data[field].length; i += 1) {
              console.log(data[field][i])
              const img = this.createInside.fromHTML(`<img src="${data[field][i].url}" src-old="${data[field][i].cid}">`)
              this.s.insertNode(img)
            }
          }

          if (data.errors && data.errors.length > 0) {
            this.message.message(data.errors.map(e => e.error + " " + e.attachment).join(', '), 'error', 5000)
          }
        },
      },
      events: {
        beforePaste: function(event) {
          const items = (event.clipboardData || event.originalEvent.clipboardData).files
          const hasImage = items.length > 0
          const editorTarget = document.querySelector("[data-jodit-target='editor']")

          if (hasImage && (!editorTarget.hasAttribute("data-images") || editorTarget.dataset.images !== "1")) {
            this.fire("errorMessage", "Sorry, You can't paste image here")
            return false
          }

          return true
        }
      }
    })
  }

  updateEditor(event) {
    event.preventDefault()
    this.updateFooter()
  }

  updateFooter() {
    if (!this.hasInputTarget) { return }

    let input = ''
    if (this.inputTarget.tagName === 'SELECT') {
      input = this.inputTarget.selectedOptions[0].value
    } else {
      input = this.inputTarget.value
    }

    const id = this.inputTarget.dataset.id
    const url = this.inputTarget.dataset.url
    const intumReply = document.querySelector('.jodit-wysiwyg > .intum_reply')

    Rails.ajax({
      url: url,
      type: 'get',
      data: id + "=" + input,
      dataType: 'script',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        console.log('update editor')
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log(partial)
        let footer = document.querySelector('.jodit-wysiwyg > .intum_footer')

        if (footer) {
          if (partial) {
            footer.outerHTML = partial
          } else {
            footer.remove()
          }
        } else if (partial) {
          footer = document.createElement('div')
          if (intumReply) {
            intumReply.parentNode.insertBefore(footer, intumReply)
          } else {
            console.log(this.inputTarget)
            const wysiwyg = document.querySelector('.jodit-wysiwyg')
            wysiwyg.appendChild(footer)
          }
          footer.outerHTML = partial
        }
      },
      error: (e) => {
        console.log('error updating')
        console.warn(e)
      }
    })
  }

  // czyszczenie edytora z lekkim opóźnieniem
  // (by formularz zdążył się najpierw wysłać w przypadku użycia funkcji po wysłaniu formularza)
  clearEditor() {
    if (this.editor) {
      setTimeout(() => {
        this.editor.value = ''
      }, 200)
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.remove()
    }
  }
}

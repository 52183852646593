import { Controller } from "@hotwired/stimulus"

/**
 * Kontroler uogólnionych formularzy
 * Chwilowo jedyna funkcja jaką posiada to zmiana checkboxów w formularzu na toggle
 * zastosowanie:
 * 1. dodajemy do formularza data-controller: "common-form"
 * 2. jeśli chcemy zmienić checkboxy dodajemy dodatkowo data-checkbox: "change"
 */

// Connects to data-controller="common-form"
export default class extends Controller {
  connect() {
    console.log("common forms connect")

    const checkboxes = this.element.querySelectorAll("input[type=checkbox]")

    if (this.element.dataset.checkbox === "change") {
      checkboxes.forEach(checkbox => {
        this.createCheckbox(checkbox)
      })
    }
  }

  createCheckbox(checkbox) {
    const newCheckbox = document.createElement('div')
    const id = checkbox.id + "_1"
    newCheckbox.setAttribute("id", id)

    const action = checkbox.dataset.action || ''
    newCheckbox.setAttribute("data-action", action + " click->common-form#checkCheckbox keydown.enter->common-form#enterClick")
    newCheckbox.setAttribute("data-common-form-field-param", checkbox.id)

    newCheckbox.classList.add('relative', 'h-5', 'w-9', 'rounded-full', 'cursor-pointer', 'duration-200', (checkbox.checked ? "bg-blue-400" : "bg-gray-300"))

    const innerDiv = document.createElement('div')
    innerDiv.classList.add('ease-out', 'duration-100', 'absolute', 'h-4', 'w-4', 'bg-white', 'top-0.5', 'left-0.5', 'rounded-full')
    if (checkbox.checked) { innerDiv.classList.add("translate-x-4") }

    newCheckbox.appendChild(innerDiv)

    checkbox.classList.add("hidden")

    checkbox.parentNode.insertBefore(newCheckbox, checkbox)
  }

  enterClick(event) {
    event.preventDefault()
    event.target.blur()
  }

  checkCheckbox(event) {
    const field = event.params.field
    const target = document.getElementById(`${field}_1`)
    let addClass = ["", ""]
    let removeClass = ["", ""]
    let checked = null
    const checkbox = document.getElementById(field)

    if (target.classList.contains('bg-blue-400')) {
      // ustawienie False
      checkbox.checked = false
      checkbox.value = "0"
      addClass = ["bg-gray-300", null]
      removeClass = ["bg-blue-400", "translate-x-4"]
      checked = false
    } else {
      // ustawienie True
      checkbox.checked = true
      checkbox.value = "1"
      addClass = ["bg-blue-400", "translate-x-4"]
      removeClass = ["bg-gray-300", null]
      checked = true
    }

    target.classList.add(addClass[0])
    target.classList.remove(removeClass[0])
    if (addClass[1]) {
      target.childNodes[0].classList.add(addClass[1])
    } else {
      target.childNodes[0].classList.remove(removeClass[1])
    }

    return checked
  }
}

import { Controller } from "@hotwired/stimulus"
import Datepicker from "../helpers/datepicker/Datepicker"
import { parseDate, formatDate } from "../helpers/datepicker/lib/date-format"

export default class extends Controller {
  static targets = ["input", "main", "calendar"]
  static values = { loaded: Boolean }
  usedEvents = []
  separator = '-'
  input = null
  datepicker = null
  availableLocales = ["en", "cs", "de", "es", "fr", "hr", "it", "ka", "pl", "sl", "sr", "uk"]
  locale = Datepicker.locales[this.getLocale()]
  format = this.locale.format

  connect() {
    console.log('calendar connect')

    this.createInput()

    this.datepicker = new Datepicker(this.calendarTarget, {
      weekstart: this.locale.weekstart,
      autohide: true,
      todayHighlight: true,
      language: this.getLocale(),
      format: this.format,
      container: `#${this.mainTarget.id}`,
    })

    this.loadedValue = true
  }

  // ustawienie języka kalendarza
  getLocale() {
    const locale = this.mainTarget.dataset.locale
    if (this.availableLocales.includes(locale)) {
      return locale
    } else {
      // jeśli nie mamy danego języka to dajemy angielski
      // (dodawanie nowych języków w../helpers/datepicker/i18n/base-locales.js)
      return this.availableLocales[0]
    }
  }

  // potrzebujemy mieć drugi input ze względu na wyświetlanie dat w formacie zależnym od lokalizacji
  createInput() {
    this.input = this.inputTarget.cloneNode(true)

    this.input = this.removeRedundantAttributes()

    this.input.setAttribute("data-action", "input->calendar#updateInput")
    this.input.setAttribute("data-calendar-target", "calendar")
    this.input.setAttribute("data-calendar-id", this.inputTarget.id)
    this.input.setAttribute("autocomplete", "off")
    this.input.value = this.initDate(this.inputTarget.value)
    this.inputTarget.after(this.input)
    this.inputTarget.classList.add('hidden')
  }

  removeRedundantAttributes() {
    this.input.id += "_calendar_input"
    this.inputTarget.removeAttribute("name")

    if (this.inputTarget.onchange) {
      this.usedEvents.push("change")
      this.input.removeAttribute('onchange')
    }
    return this.input
  }

  // przerabia yyyy/mm/dd z początkowej wartości z formularza na odpowiedni format lokalizacyjny
  initDate(value) {
    if (!value) return ""
    this.separator = value[value.search(/\D/)]
    const date = parseDate(value, `yyyy${this.separator}mm${this.separator}dd`, this.locale)
    return formatDate(date, this.format, this.format)
  }

  // przerabia format lokalizacyjny na yyyy/mm/dd
  updateDate() {
    const date = parseDate(this.calendarTarget.value, this.format, this.format)

    // nie aktualizujemy daty, jak ktoś wpisał coś głupiego (np.: 2023-18-20) bo pewnie jeszcze nie skończył wpisywać
    if (this.calendarTarget.value.replace(/[^0-9]/gi, '') !== formatDate(date, this.format, this.locale).replace(/[^0-9]/gi, '')) {
      return false
    }

    this.inputTarget.value = formatDate(date, `yyyy${this.separator}mm${this.separator}dd`, this.locale)
    this.usedEvents.forEach(event => {
      this.inputTarget.dispatchEvent(new Event(event))
    })
  }

  // akcja po wpisaniu ręcznie daty
  // robimy to sprytnie - tzn. jeśli wpisana jest już cała data to każda kolejna cyfra zastępuje obecnie zaznaczoną
  // np.: dla daty 2023-0|8-12 (gdzie | oznacza miejsce gdzie obecnie znajduje się kursor)
  // po wpisaniu '3' data zmieni się w 2023-03-|12 (kursor przeskoczy od razu myślnik)
  updateInput() {
    const value = this.input.value
    let index = this.input.selectionStart

    // jeśli długość jest mniejsza, to znaczy że data jeszcze nie jest pełna
    if (value.length < 10) {
      return true
    } else if (value.length === 10) {
      this.updateDate()
      this.input.setSelectionRange(index, index)
      return true
    }

    // jeśli ktoś pisze na końcu daty (która jest już pełna) to zamieniamy ostatnią cyfrę daty
    if (index >= value.length) {
      index = value.length - 2
    }

    // akcja gdy w miejscu cyfry ktoś wpisze myślnik/slash/kropkę lub w miejscu myślnika wpiszę cyfrę
    if (isNaN(value[index]) !== isNaN(value[index - 1])) {
      // jeśli ktoś wpisuje cyfrę w miejsce kropki/myślnika to zamieniamy poprzednią cyfrę
      this.input.value = value.slice(0, index - 2) + value.slice(index - 1, value.length)
      index -= 1
    } else {
      // podmieniamy następny znak na nowo wpisany
      this.input.value = value.slice(0, index) + value.slice(index + 1, value.length)
    }

    // przeskakujemy kropki/myślniki itp.
    if (isNaN(value[index + 1])) {
      index += 1
      console.log("nan")
    }

    this.updateDate()
    this.input.setSelectionRange(index, index)

    // to aktualizuje kalendarz po wpisaniu ręcznie daty
    this.datepicker.update()
  }
}

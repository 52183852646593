import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["menu", "preview", "email", "li", "form", "option", "clock"]
  clockOffset = 0

  connect() {
    console.log('connect1 email')

    if (this.hasMenuTarget) {
      const hidden = this.menuTarget.classList.contains('hidden')
      useTransition(this, { element: this.menuTarget })

      if (!hidden || this.menuTarget.id === window.location.hash.split("#")[1] + '_content') {
        this.toggleLink()
      }
    }

    this.jumpToEmail()

    if (this.hasFormTarget) {
      this.formTarget.onkeypress = function(event) {
        var key = event.key
        if (key === 'Enter') {
          event.preventDefault()
        }
      }
    }

    if (this.hasClockTarget) {
      this.clockOffset = parseInt(this.clockTarget.innerHTML) - new Date().getTime()
      this.startTime()
    }
  }

  jumpToEmail() {
    if (this.hasLiTarget && this.liTarget.id === this.liTarget.dataset.anchorId) {
      this.liTarget.scrollIntoView(true)
    }
  }

  toggleLink() {
    const hidden = this.menuTarget.classList.contains('hidden')
    if (hidden) {
      this.toggle()
    }
  }

  toggleSmtp() {
    document.getElementById('mailbox_use_smtp').value = this.menuTarget.classList.contains('hidden')
    this.toggle()
  }

  toggleOption() {
    this.optionTarget.value = this.menuTarget.classList.contains('hidden')
    this.toggle()
  }

  toggle() {
    console.log('toggle1')
    this.toggleTransition()
  }

  toggleEmail() {
    if (this.previewTarget.classList.contains('hidden')) {
      this.previewTarget.classList.remove('hidden')
      this.liTarget.classList.remove('hover:bg-gray-50')
      this.emailTarget.classList.add('hidden')
    } else {
      this.previewTarget.classList.add('hidden')
      this.liTarget.classList.add('hover:bg-gray-50')
      this.emailTarget.classList.remove('hidden')
    }
  }

  showField(div) {
    if (div.classList.contains('hidden')) {
      div.classList.remove('hidden')
    } else {
      div.classList.add('hidden')
    }
  }

  showCc() {
    const div = document.getElementById('mail_email_cc_div')
    this.showField(div)
  }

  showBcc() {
    const div = document.getElementById('mail_email_bcc_div')
    this.showField(div)
  }

  showMailbox() {
    const mailbox = document.getElementById('email_mailbox_id')
    const div = document.getElementById('mail_email_mailbox_div')

    this.showField(mailbox)
    this.showField(div)
  }

  changeMailbox() {
    const mailbox = document.getElementById('email_mailbox_id')
    console.log('change mailbox')
    document.getElementById('mail_email_mailbox').textContent = mailbox.selectedOptions[0].textContent

    const div = document.getElementById('mail_email_mailbox_div')
    mailbox.classList.add('hidden')
    div.classList.remove('hidden')
  }

  // generalnie pokazywanie zegara w JSie jest jakieś nieoczywiste i skomplikowane
  startTime() {
    const today = new Date(new Date().getTime() + this.clockOffset)
    this.clockTarget.innerHTML = today.toLocaleString()
    setTimeout(() => { this.startTime() }, "1000")
  }

  updateTimezone(event) {
    const timezone = event.target.value
    const url = this.clockTarget.dataset.url

    console.log(url)
    Rails.ajax({
      url: url,
      type: 'get',
      data: `timezone=${timezone}`,
      dataType: 'script',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        console.log('update date')
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log(partial)
        if (partial) {
          this.clockOffset = parseInt(partial) - new Date().getTime()
        }
      },
      error: (e) => {
        console.log('error updating')
        console.warn(e)
      }
    })
  }

  // zapisywanie wersji roboczej (chwilowo co 30 sekund)
  saveDraft() {
    setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
      this.saveDraft()
    }, "30000")
  }

  disconnect() {
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="draft"
export default class extends Controller {
  static values = { url: String, interval: Number, excluded: String }

  connect() {
    this.autoSaveInterval = setInterval(() => {
      this.saveDraft()
    }, this.intervalValue)
    console.log(this.autoSaveInterval)
  }

  getFormDataAsJSON(form) {
    const formData = new FormData(form)
    const object = {}
    let excludedElements = []
    if (this.hasExcludedValue) {
      excludedElements = this.excludedValue.split(',')
    }

    formData.forEach((value, key) => {
      if (excludedElements.includes(key)) { return }
      // Check if key can be split into object and property
      if (key.includes('[') && key.includes(']')) {
        // Split the key into components
        const components = key.split('[')
        const objectKey = components[0]
        const propertyKey = components[1].replace(']', '')

        // Create the object if it doesn't exist
        if (!object[objectKey]) {
          object[objectKey] = {}
        }

        // Assign the value to the property in the object
        object[objectKey][propertyKey] = value
      } else {
        // Assign the value directly to the key in the object
        object[key] = value
      }
    })

    return JSON.stringify(object)
  }

  saveDraft() {
    this.showProgress(1)
    console.log('saveDraft')
    const form = this.element
    const json = this.getFormDataAsJSON(form)
    const url = this.urlValue
    const csrfToken = document.querySelector('[name="csrf-token"]').content

    fetch(url, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: json,
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .then(
        this.showProgress(2)
      )
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // status values:
  // 0 - no saving
  // 1 - saving in progress
  // 2 - saving finished
  showProgress(status) {
    const progress = document.getElementById('saving_draft_progress')
    const finished = document.getElementById('saving_draft_finished')

    if (!progress || !finished) { return }

    switch (status) {
      case 0:
        progress.classList.add("hidden")
        finished.classList.add("hidden")
        break
      case 1:
        progress.classList.remove("hidden")
        finished.classList.add("hidden")
        break
      case 2:
        progress.classList.add("hidden")
        finished.classList.remove("hidden")
        setInterval(() => {
          this.showProgress(0)
        }, "4000")
        break
    }
  }

  disconnect() {
    clearInterval(this.autoSaveInterval)
  }
}

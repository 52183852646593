import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["from", "to", "workHours", "totalHours", "totalDays", "checkbox", "customHours", "hoursTeamplate"]
  custom = false

  connect() {
    console.log('connect1')

    this.customHoursVisible = false
    this.customHours = JSON.parse(this.customHoursTarget.value.replaceAll(`=>`, `: `))

    this.fromTarget.addEventListener('generated_change', event => {
      this.changeLeaveTimes(event)
    })

    this.toTarget.addEventListener('generated_change', event => {
      this.changeLeaveTimes(event)
    })
  }

  getHours(day) {
    const isWeekend = (day === 6) || (day === 0) // 6 = Saturday, 0 = Sunday
    const index = (day - 1) % 7
    return parseFloat(isWeekend ? 0 : this.workHoursTargets[index].value)
  }

  // zamienia obiekt Data na string yyyy-mm-dd
  dateToDateValue(date) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()

    return `${yyyy}-${mm}-${dd}`
  }

  // zamienia string yyyy-mm-dd na obiekt Data
  dateValueToDate(dateValue) {
    const parts = dateValue.split(`-`)
    const dd = parts[2]
    const mm = parts[1] - 1
    const yyyy = parts[0]

    var date = new Date(yyyy, mm, dd)
    date.setHours(0, 0, 0, 0)
    return date
  }

  // tworzy jedno pole, nigdzie go nie przypina, i je zwraca
  createField(date, hours) {
    const dateValue = this.dateToDateValue(date)
    const template = this.hoursTeamplateTarget
    const tempDiv = document.createElement('div')

    tempDiv.innerHTML = template.innerHTML

    const dateElement = tempDiv.firstElementChild.querySelector(`#employee_leave_custom_date`)
    const hoursElement = tempDiv.firstElementChild.querySelector(`#employee_leave_custom_hours`)
    const hoursDiv = tempDiv.firstElementChild.querySelector(`#custom_hours_container`)

    dateElement.value = dateValue

    hoursDiv.id = `employee_leave_custom_hours_container_${dateValue}`
    hoursElement.id = `employee_leave_custom_hours_${dateValue}`
    hoursElement.name = `employee_leave[custom_hours][${dateValue}]`
    hoursElement.value = hours

    return tempDiv.firstElementChild
  }

  // updatuje sumy godzin i dni, oraz hash customowych godzin, gdy ktoś zmieni pole
  changeField(event) {
    const newHours = event.target.value
    const dateValue = event.target.id.split(`_`).pop()
    const date = this.dateValueToDate(dateValue)

    var oldHours = this.customHours[dateValue]
    if (oldHours == null) oldHours = this.getHours(date.getDay())

    this.customHours[dateValue] = newHours
    const change = newHours - oldHours

    this.totalHoursTarget.value = (change + parseFloat(this.totalHoursTarget.value)).toFixed(2)
    this.totalDaysTarget.value = (this.totalHoursTarget.value / 8).toFixed(2)
  }

  fromToInRange(from, to) {
    if (!from || !to || from > to) {
      return false
    }

    return from.getFullYear() >= 2000 && to.getFullYear() <= 2100 && to.getFullYear() - from.getFullYear() < 2
  }

  // tworzy pola custom_hours
  createCustomHours(toggleVisibility = true) {
    const container = document.getElementById(`custom-hours-container`)

    var from = new Date(this.fromTarget.value)
    var to = new Date(this.toTarget.value)
    from.setHours(0, 0, 0, 0)
    to.setHours(0, 0, 0, 0)

    if (!this.fromToInRange(from, to)) return

    if (toggleVisibility) {
      container.classList.toggle('hidden')
      this.customHoursVisible = !this.customHoursVisible
    }

    if (!this.customHoursVisible) return

    // chowamy pola poza zakresem [from, to]
    const fields = container.querySelectorAll(`[id^="employee_leave_custom_hours_container_"]`)
    fields.forEach(field => {
      const fieldDateValue = field.id.split(`_`).pop()
      const fieldDate = this.dateValueToDate(fieldDateValue)
      console.log(`date: ${fieldDate} from: ${from}`)

      fieldDate < from || fieldDate > to ? field.parentElement.classList.add('hidden') : field.parentElement.classList.remove('hidden')
    })

    let date = new Date(from)
    let lastField = container.children[0] //template

    while (date <= to) {
      const oldField = container.querySelector(`[id^="employee_leave_custom_hours_container_${this.dateToDateValue(date)}"]`)

      if (!oldField) {
        var hours = this.customHours[this.dateToDateValue(date)]
        if (hours == null) hours = this.getHours(date.getDay())

        const currentField = this.createField(date, hours)
        lastField.after(currentField)
        lastField = currentField
      } else {
        lastField = oldField.parentNode
      }

      const newDate = date.setDate(date.getDate() + 1)
      date = new Date(newDate)
    }
  }

  setHoursAndDays() {
    var totalHours = 0
    const from = new Date(this.fromTarget.value)
    const to = new Date(this.toTarget.value)

    if (!this.fromToInRange(from, to)) {
      this.totalHoursTarget.value = 0
      this.totalDaysTarget.value = 0
      return
    }

    let date = new Date(from)

    while (date <= to) {
      var hours = this.customHours[this.dateToDateValue(date)]
      if (hours == null) hours = this.getHours(date.getDay())

      totalHours += hours

      const newDate = date.setDate(date.getDate() + 1)
      date = new Date(newDate)
    }

    this.totalHoursTarget.value = totalHours
    this.totalDaysTarget.value = totalHours / 8
  }

  changeLeaveTimes(event) {
    const from = this.fromTarget.value
    const to = this.toTarget.value

    console.log(`${from} ${to}`)
    if (!from || !to) return

    this.setHoursAndDays()
    if (this.customHoursVisible) this.createCustomHours(false)
  }
}
